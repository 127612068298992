.login {
  h2,
  h3,
  p,
  span {
    &,
    & * {
      color: #fff;
    }
  }

  p,
  span,
  a {
    font-weight: 300;
  }

  p,
  span {
    color: $secondary-text-color;
  }

  a {
    color: $secondary-color;

    &:hover {
      color: $secondary-color;
    }

    &:active {
      color: $secondary-color;
    }
  }

  @include media('<=sm') {
    flex-direction: column-reverse;
  }

  & &__right,
  & &__left {
    @include flex-vertical-center-column;

    width: 50vw;
    height: 100vh;
    @include media('<sm') {
      max-height: unset;
    }
    background-color: #1a1a1a;

    @include media('<=sm') {
      width: 100vw;
      height: auto;
    }
  }

  & &__left {
    padding: 4% 6% 0 6%;

    &__top {
      display: table;
      margin: 0 auto;
      text-align: center;
      max-width: 700px;

      img {
        display: block;
        width: 200px;
        margin: auto;
      }

      img,
      p {
        margin-bottom: 3.5em;
      }
    }

    &__inner {
      max-width: 800px;
      padding-bottom: 4%;

      @include media('<sm') {
        overflow: unset !important;
      }
    }

    .col {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  & &__right {
    height: auto !important;
    padding: 0.85vw;

    &__inner {
      @include flex-vertical-center-column;
      justify-content: space-between !important;
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: 20px;
      background: url('/dashboard/login/login-right-backround.jpg'),
        linear-gradient(-48.28deg, #22c9cd, #1e2a6f);
      background-size: cover;
      background-position: bottom;

      & > * {
        width: 100%;
      }
    }
    //lukas+23123123@meetovo.de
    .intro-text {
      max-width: 100%;

      a {
        color: $color-high-contrast;
      }
    }

    h1,
    h2,
    p {
      text-align: center;

      &,
      & * {
        color: $color-high-contrast;
      }
    }

    h1 {
      font-size: 3.2em;
      font-weight: 300;
      margin-bottom: 1em;
      line-height: 1.5em;
      margin-top: 30px;

      @include media('<=sm') {
        font-size: 2em;
      }
    }

    h2 {
      line-height: 2rem;
      font-size: 30px;
    }

    p {
      font-size: 1.2rem;
    }

    .circles {
      height: 5vw;
      width: 5vw;

      &__1 {
        position: absolute;
        top: 9%;
        left: 8%;
      }

      &__2 {
        position: absolute;
        top: 6%;
        left: 33%;
      }

      &__3 {
        position: absolute;
        top: 3%;
        left: 58%;
      }

      &__4 {
        position: absolute;
        top: 9%;
        left: 80%;
      }
    }
  }

  .bp-max-768,
  .bp-max-768-flex,
  .bp-min-769-flex {
    display: none !important;
  }

  @media screen and (min-width: 769px) {
    .bp-min-769-flex {
      display: flex !important;
    }
  }

  @media screen and (max-width: 768px) {
    .bp-max-768 {
      display: block !important;
    }
    .bp-max-768-flex {
      display: flex !important;
    }
  }

  .divider {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    margin-inline: 8px;
    color: $color-high-contrast;
    width: 7%;
    text-align: center;

    &-container {
      display: flex;
      margin: 2rem 0rem;
      align-items: center;
      justify-content: center;
      width: 100%;
      color: $medium-light-grey;
    }

    &-line {
      display: block;
      content: ' ';
      width: 46.5%;
      height: 1.5px;
      background-color: $medium-light-grey;
    }
  }

  .button {
    &__base {
      height: initial !important;
      width: 100%;
      padding: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      color: $color-high-contrast;
      border-radius: 50px;

      &,
      & span {
        font-weight: 400;
        color: #fff;
      }
    }

    &__gradient {
      @extend .button__base;
      background: linear-gradient(158deg, #1e2a6f 43%, #22c9cd);
      border: none;
    }

    &__google {
      @extend .button__base;
      border: 1px solid #24d3d4 !important;
      background: transparent;

      a:hover {
        color: $color-high-contrast !important;
      }

      &:before {
        content: ' ';
        background: url('/dashboard/login/google-icon.svg');
        background-size: 100%;
        width: 20px;
        height: 20px;
        margin-right: 15px;
      }
    }
  }

  .form {
    &-input {
      &.ant-form-item-has-error {
        input {
          background-color: $black-background !important;
        }
        input[type='password'] {
          font-family: caption;
          height: 50px;
          background: transparent;
          border: none !important;
        }

        .ant-input-password {
          &.ant-input-affix-wrapper {
            height: 60px;
            border-radius: 7px !important;
            background-color: $black-background !important;
          }

          input {
            height: 50px;
            background-color: transparent;
          }
        }
      }

      .ant-input-password-icon {
        color: rgb(242, 242, 242) !important;
      }

      label {
        font-style: normal;
        font-weight: bold;
        font-size: $font-size-default;
        line-height: 25px;
        color: $color-high-contrast !important;
        opacity: 0.8;
      }
      input {
        width: 100%;
        height: 60px;
        background: $secondary-color;
        background: #1f2124;
        border-radius: 7px;
        border: 1px solid rgba(35, 211, 211, 0.3);
        font-style: normal;
        font-weight: 700;
        line-height: 27px;
        color: $color-high-contrast;
        &::placeholder {
          font-weight: 500;
          line-height: 20px;
          font-style: normal;
          text-align: left;
          color: #ffffff24;
        }
      }
      input[type='password'] {
        font-family: caption;
        height: 50px;
        background: transparent;
      }
      input[type='password']:placeholder-shown {
        font-family: caption;
        height: 50px;
        background: transparent;
      }
      input:-webkit-autofill,
      textarea:-webkit-autofill,
      select:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 1000px #152626 inset !important;
        -webkit-text-fill-color: white !important;
        background: #152626 !important;
        color: $color-high-contrast !important;
        &[type='password'] {
          border: none !important;
          border-radius: 0px;
        }
      }
      input:placeholder-shown {
        border: none;
        background-color: #1f2124;
      }

      .ant-input-password {
        &.ant-input-affix-wrapper {
          height: 60px;
          background: rgba(35, 211, 211, 0.1);
          border-radius: 7px !important;
          border: 1px solid rgba(35, 211, 211, 0.3);
        }
        &.ant-input-affix-wrapper:has(> input[type='password']:placeholder-shown) {
          background-color: #1f2124;
          border: none;
        }
        input {
          height: 50px;
          background-color: transparent;
        }
      }
      .check-box {
        display: flex;
        align-items: center;
        margin: 0px 0px 20px !important;
      }
    }
  }

  .horizontal-list-of-logos {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-around;
    width: 100%;
    max-width: 1000px;
    padding: 30px;
    margin: 0;

    &__item:last-child {
      margin-right: 0;
    }

    &__image {
      height: 40px;
    }

    @media (max-width: 991.98px) {
      &__item {
        margin-right: 20px;
      }
      &__image {
        height: 22px;
      }
    }
  }

  .forgot-pswd-btn {
    display: flex;
    justify-self: end;
    margin-top: -25px;
    margin-bottom: 25px;

    & > span {
      color: $secondary-color;
      font-weight: 400;
      font-size: 14px;

      &:hover {
        color: $secondary-color;
      }
    }
  }

  .register-or-login {
    text-align: center;
    width: 100%;
    margin-bottom: 3.5em;
  }

  .ant-carousel {
    .slick-dots-bottom {
      bottom: 0;
      @include media('<sm') {
        bottom: 15px;
      }
    }
    .slick-slide > div {
      justify-content: center;
      display: flex;
    }
    .slick-dots li button {
      height: 10px !important;
      border-radius: 50px !important;
    }
    .slick-track {
      @include flex-vertical-center;
    }
  }

  .login__carousel {
    &__item {
      text-align: center;
      margin: 5%;
      max-width: 700px;
      color: #fff !important;

      @include media('<sm') {
        padding: 5%;
      }

      &__top {
        margin-bottom: 20px;

        & > * {
          margin: 0 auto;
        }

        .podcast-cover {
          max-width: 260px;
          filter: drop-shadow(5px 8px 15px rgba(0, 0, 0, 0.75));
        }
      }

      .ant-btn {
        &,
        & * {
          color: #fff !important;
        }

        background: transparent !important;
        border-radius: 60px !important;

        @include media('<sm') {
          margin-bottom: 15px;
        }

        svg {
          margin-top: -3px !important;
          margin-right: 0 !important;
        }
      }
    }
  }

  .avatar-group {
    @include flex-vertical-center;

    &__inner {
      @include flex-vertical-center;

      .ant-avatar {
        margin-right: -10px;
        box-shadow: -8px 2px 10px -4px #000;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &__count {
      padding: 0.25em 0.5em;
      font-weight: 300;
      color: #fff;
    }
  }

  .trusted {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
      height: 30px !important;
      @include media('<lg') {
        height: 20px !important;
      }
    }

    &__title {
      @include media('<sm') {
        padding: 5%;
      }
    }
  }

  background-color: $black-background;
}

.email-login-and-registration {
  &__switch-mode {
    padding: 0 !important;
    margin-bottom: 1em !important;
  }
}

.hide-scroll-bar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.senja {
  &__rating-average {
    margin-top: 30px;
  }
}

[current-route*='registrieren'],
[current-route*='login'] {
  .help-button-container {
    bottom: 1.5vw;
    right: 1.5vw;

    .circle-button {
      background: rgba(255, 255, 255, 0.25) !important;
      border: 1.5px solid #fff !important;
    }
  }
}

.alert-error-message {
  border: 1px solid #ff4d4f !important;
  background: none !important;
  .anticon * {
    color: #ff4d4f !important;
  }
  .ant-alert-message {
    color: #ff4d4f !important;
  }
  .ant-alert-description {
    color: #ff4d4f !important;
  }
}

.login-as-admin .ant-btn span {
  color: white !important;
}
