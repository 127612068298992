.kanban-parent-container {
  height: calc(100vh - 170px);
  scroll-behavior: unset !important;
  @include breakpoint(max_575) {
    height: calc(100vh);
  }
  .kanban-container {
    display: flex;
    margin-right: 40px;
    display: inline-flex;
    & :first-child.parent-kanban-column-wrapper {
      margin-left: 0px;
    }
  }
}

.error-message {
  color: #ff4d4f;
  font-size: 14px;
  line-height: 1.5715;
}

.kanban-colum-title {
  background-color: white;
  padding: 10px;
  cursor: grab;

  .ant-tag {
    cursor: pointer !important;
  }

  .color-picker__overlay {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 11;
    width: 100% !important;
    cursor: default;
  }
}

.kanban-colum-add-new {
  background-color: $light-grey;
  margin-left: 5px;
  height: max-content;
  display: flex;
  flex-direction: column;
  border-radius: 8px;

  .controls {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 15px;
  }

  .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
  }
}

.kanban-column-container {
  flex-direction: column;
  width: 340px;
  background-color: $light-grey;
  height: calc(100vh - 170px);
  @include breakpoint(max_575) {
    height: calc(100vh);
  }
  border-radius: 8px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  overflow-y: auto;
}

.parent-kanban-column-wrapper {
  margin-left: 5px;
  margin-right: 5px;

  .cursor-default-on-title-editing {
    cursor: default !important;
  }
}

.column-title {
  padding: 2px 5px;
  font-weight: 600;
  color: #303030;
}

.kanban-column-header {
  min-width: 260px;
  display: flex;
  background: $light-grey;
  justify-content: space-between;
  align-items: center !important;
  padding: 10px 15px;
  border-radius: 8px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;

  .ant-tag {
    margin: 0 !important;
  }

  .title-invalid {
    border: 1px solid #ff4d4f;
    margin-right: 10px;
  }

  .column-controls {
    display: flex;
    position: relative;
    align-items: center;
  }

  .edit-icon {
    position: absolute;
    right: -30px;
    height: 30px;
    display: none;
    align-items: center;
    width: 30px;

    * {
      font-size: 12px;
    }
  }

  p {
    margin-bottom: 0px !important;
  }

  button {
    max-height: fit-content !important;
    padding-inline: 10px !important;
  }

  .scrollable-title {
    max-width: 90px;
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none;
    white-space: nowrap;
    margin-right: 6px;
  }
  .scrollable-title::-webkit-scrollbar {
    display: none;
  }
  .editable-title {
    position: relative;
    display: flex;
    align-items: center;

    &__count {
      color: $secondary-text-color;
    }
  }

  .editable-title:hover {
    .edit-icon {
      display: flex;
    }
  }
}

.kanban-toggle-header,
.kanban-overlay-wrapper {
  .ant-select {
    margin-right: 15px;
  }
  .ant-radio-group {
    display: flex;
  }
  .ant-select-selection-placeholder {
    text-align: left !important;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(20px);
    }
  }

  .hover-items-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    position: absolute;
    top: 100%;
    opacity: 0;
    visibility: hidden; // Start as hidden
    padding-bottom: 30px;

    // Fade-in animation
    &.fade-in {
      animation: fadeIn 0.4s forwards;
      opacity: 1;
      visibility: visible; // Becomes visible on fade-in
    }

    // Fade-out animation
    &.fade-out {
      animation: fadeOut 0.4s forwards;
      opacity: 0;
      visibility: hidden; // Hides after fade-out
    }

    h1,
    p {
      text-align: center;
    }

    img {
      max-width: 100%;
    }
  }
  .event-questions-display {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.application-choices-main-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  position: relative;
  .choices-row-wrapper {
    display: flex;
    gap: 15px;
    width: 100%;
  }
  .question-choices-card-wrapper {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.5em 0;
    width: 100%;
    border-radius: 5px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
    gap: 10px;

    label {
      text-align: center;
      padding: 0em 1em;
    }
  }
}

.card-image-icon-main-wrapper {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  img {
    max-width: 28%;
    margin-top: 20px;
    object-fit: contain;
    height: 100%;
  }
}

.icon-wrapper {
  margin-top: 20px;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  svg {
    font-size: 75px;
  }
}

.kanban-column-title {
  margin-bottom: 0px;
  cursor: default !important;
  font-size: $font-size-default;
  font-weight: 600;
}

.kanban-header {
  position: fixed;
  z-index: 1;
  top: 40;
  padding: 15px;
  width: calc(100vw - calc($sidebar-width + 80px));
  @include breakpoint(max_993) {
    width: calc(100vw - 6%);
  }

  @include breakpoint(max_575) {
    .flex-vertical-center-column-inline:last-child {
      display: block;
    }

    .kanban-justify__content {
      justify-content: space-between;
      margin-top: 20px;
    }
  }
}

.event-list-wrapper {
  padding: 10px 15px;
  transition: background-color 0.2s ease, opacity 0.1s ease;
  user-select: none;
}

.event-list-dropzone {
  min-height: calc(100vh - 340px);
  height: max-content;
  padding-bottom: 10px;
}

.event-item-container {
  background-color: white;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transform: translateY(-1.5px);
  }
  &:active {
    color: purple;
    text-decoration: none;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }

  .sb-avatar {
    margin-right: 3px;
  }

  .rating-box {
    padding: 1px 5px;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
    text-align: right;
    width: fit-content;
    margin-left: auto;
  }
}

.event-item-container-draggable {
  border: 2px solid $secondary-color;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(-10px);
}

.kanban-justify__content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.kanban-spinner-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  position: fixed;
  z-index: 9;
}

.kanban-overlay-blurred {
  filter: blur(1.5px);
}

.kanban-overlay-wrapper {
  position: relative;
  margin-top: 15px;
}

.event-item-content {
  flex-grow: 1;
  flex-basis: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 5px;
  flex-direction: column;
  .event-name-span {
    padding-top: 10px !important;
  }
  .event-name-items {
    font-weight: 600;
    text-overflow: ellipsis;
    padding-top: 5px;
    white-space: nowrap;
    overflow: hidden;
  }
  .event-email-items {
    margin-bottom: 3px;
    max-width: 90%;
    word-wrap: break-word;
  }
  .ant-col {
    padding: 2px 8px !important;
  }
  .event-date {
    display: flex;
    align-items: center;
    font-size: $font-size-medium-small;

    span {
      margin-right: 5px;
    }

    .event-date-content {
      margin-bottom: 3px;
      word-wrap: break-word;
    }
  }
}

.event-booking-action-event {
  cursor: pointer;
  text-align: right;
  padding: 2px 0;
}
.event-item-footer {
  display: flex;
  align-self: flex-end;
  padding-right: 5px;
  small {
    flex-grow: 1;
    flex-shrink: 1;
    margin: 0;
    color: skyblue;
    font-weight: normal;
    text-overflow: ellipsis;
    text-align: right;
  }
}

.applicant-pipeline-progress {
  margin-bottom: 20px;

  & .ant-avatar {
    background-color: #f5f5f5 !important;
  }

  &__column {
    &:last-child {
      max-width: 320px;
      width: 100%;
    }
  }

  .ant-avatar {
    margin-right: 20px;
  }

  h4 {
    margin-bottom: 0 !important;
  }
}

.heading-margin {
  margin-right: 1rem !important;
  @media (max-width: 700px) {
    margin-right: 0.5rem !important;
  }
}

.selection-button {
  @media (max-width: 700px) {
    span {
      display: none;
    }
  }
}

.color-picker-fixed-center {
  position: fixed;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  background: black;
  color: white;
  background-color: #fff;
  padding: 10px 12px !important;
  box-shadow: 3px 3px 10px 0px rgb(0 0 0 / 10%);
  border-radius: $default-border-radius;
  border: 1px solid $form-border-grey;
}

.anti-styles-color-picker {
  box-shadow: none;
  border: none;
  background: transparent;
}

.color-circle__circle__small {
  height: 12px !important;
  width: 12px !important;
  min-height: 12px !important;
  min-width: 12px !important;
  box-shadow: none !important;
  border: none !important;
  margin-right: 5px !important;
}

.funnel-icon-button {
  color: '#fff';
}
.file-name-button {
  text-wrap: wrap !important;
  text-align: left !important;
  cursor: pointer;
  color: #1890ff;
  &:hover {
    color: #1e266d !important;
  }
}

.rating-tooltip {
  width: 200px;
  .ant-col {
    padding: 2px 8px !important;
  }
  .ant-typography {
    color: #909090 !important;
    font-size: 12px;
    strong {
      color: white !important;
    }
  }
  .title {
    font-size: 14px !important;
    color: white !important;
  }
}
