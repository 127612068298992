#root > div > section > section > main {
  @media screen and (max-width: 789px) {
    padding: 20px 10px;
  }
}
.events {
  &__right {
    display: flex;
    align-items: center;

    .anticon {
      color: #1890ff;
    }

    & > .anticon {
      font-size: 1.75em;
    }

    & > * > .anticon {
      margin-left: 15px;
    }
  }

  .ant {
    &-empty {
      padding: 5% 0;

      img {
        max-width: 100%;
      }
    }

    &-collapse {
      background-color: transparent;
    }

    &-layout {
      &-sider {
        max-width: 50px !important;
        min-width: 50px !important;
        background-color: white !important;
        padding-left: 10px !important;

        &-light {
          max-width: 50px !important;
          min-width: 50px !important;
          background-color: white !important;
          padding-left: 10px !important;
        }
      }
    }

    &-btn-circle {
      @media (max-width: 408px) {
        margin-left: 0;
      }
    }

    &-pagination {
      margin-right: 20px !important;
    }

    &-table {
      .ant-table-content {
        overflow: auto;
        overflow-y: hidden !important;
      }

      &-row {
        cursor: pointer;
      }
    }
  }
  &-container {
    .ant-card-body {
      padding: 0px;
    }
  }
  .table-column-icon {
    padding: 0 10px;
    width: 30px;
  }
  .table-column-status {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .ant-tag {
      margin-bottom: 5px;
      border-radius: 20px;
      display: flex;
      padding-top: 3px;
      padding-bottom: 3px;
      align-items: center;
      justify-content: center;
      .tag-point {
        height: 10px;
        width: 10px;
        margin-right: 5px;
        border-radius: 10px;
        opacity: 0.6;

        &.red {
          background-color: red;
        }

        &.cyan {
          background-color: cyan;
        }

        &.blue {
          background-color: blue;
        }

        &.green {
          background-color: green;
        }
      }
    }
    &__date {
      @media screen and (max-width: 789px) {
        display: none;
      }
    }
  }
  .action-button__wrapper {
    @media screen and (max-width: 789px) {
      .ant-btn {
        min-width: 30px !important;
        width: 30px !important;
        height: 30px !important;
        font-size: 14px !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .ant-picker.ant-picker-range {
    margin-right: 1em;
  }

  &__filter-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .download-btn {
      color: $primary;
      margin-right: 15px !important;
    }

    & > div {
      display: flex;

      .ant-input-search {
        max-width: 300px;
        width: 100%;
        margin-bottom: 0.5em;
      }
    }
    & > * {
      margin-right: 0.5em;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .anticon-sync {
    font-size: 1.65em;
    align-self: center;
  }

  @include breakpoint(max_575) {
    .csv-download__wrapper {
      display: none;
    }
  }
}

.site-page-header .ant-page-header-heading-title {
  cursor: pointer;
  font-weight: 500;
  vertical-align: middle;

  span.ant-tag {
    margin-left: 1em !important;
    transform: translateY(-2px) !important;
  }
}

.event-summary-modal {
  .ant-drawer-content-wrapper {
    width: 720px !important;

    @include breakpoint(max_sm) {
      width: 95% !important;
    }
  }

  h4.ant-typography {
    font-size: 1em;
  }
}

.info-card-text {
  width: 20rem;
  background-color: #fff;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.info-icon {
  font-size: 0.7rem;
  color: black;
  margin: -6px 0px 0px 5px;
}

.notes {
  &__add {
    position: fixed !important;
    bottom: 0;
    width: 672px;
  }
  &__list {
    position: relative;
  }

  &__list.compact-view {
    .ant-list-items {
      max-height: 26vh;
      overflow: auto;
    }

    @media (max-height: 799px) {
      .ant-list-items {
        max-height: 26vh;
      }
    }

    @media (min-height: 800px) and (max-height: 900px) {
      .ant-list-items {
        max-height: 22vh;
      }
    }

    @media (min-height: 900px) and (max-height: 1020px) {
      .ant-list-items {
        max-height: 28vh;
      }
    }

    @media (min-height: 1020px) and (max-height: 1092px) {
      .ant-list-items {
        max-height: 34vh;
      }
    }

    @media (min-height: 1093px) {
      .ant-list-items {
        max-height: 38vh;
      }
    }

    .ant-list-empty-text {
      display: none;
    }
  }

  &__color-picker {
    & > .color-picker__overlay {
      top: unset;
      left: unset;
      background: unset;
      right: unset;
    }
  }

  .sb-avatar__text span {
    font-size: 14.3118px !important;
  }

  .default-empty.ant-empty {
    padding: 40px !important;
  }
}
.btn-success {
  background-color: #7ad8a0 !important;
  border: #7ad8a0 !important;
}

.booking-note {
  display: flex;
  justify-content: space-between;

  .edit-or-delete-note {
    position: absolute;
    top: -7px;
    right: 15px;

    .ant-btn {
      padding: 0;
    }
  }
}

.filter-icon {
  font-size: 16;
  color: '#3DA0F2' !important;
}

.event-name {
  text-transform: 'capitalize';
  font-weight: 600;
}

.average-rating {
  .ant-rate {
    font-size: 14px;
  }

  .ant-rate-star:not(:last-child) {
    margin-right: 3px;
  }
}

.rating-progress {
  .ant-progress-bg {
    background-color: #fadb13;
  }
  span.ant-progress-text {
    opacity: 0;
  }
  .ant-progress-show-info .ant-progress-outer {
    padding-right: 0px;
  }
}

.rating-title {
  font-weight: 600;
  font-size: 16px;
}

.rating-form-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px !important;

  .label {
    margin-right: 5px;
  }
}

.average {
  text-align: center;
  .symbol {
    font-size: 36px;
  }
  .rating {
    font-size: 48px;
    font-weight: 600;
  }
}

.editable-paragraph .anticon {
  color: gray !important;
}

.editable-paragraph .ant-typography-edit {
  opacity: 0 !important;
}

.editable-paragraph:hover .ant-typography-edit {
  opacity: 1 !important;
}

.editable-paragraph.link-style {
  color: $link-color;
  text-decoration: none;
}

.editable-paragraph.link-style:hover {
  text-decoration: underline;
  cursor: pointer;
}

.applications-card .ant-card-head {
  padding: 0;
}

.applications-card .ant-card-body {
  padding: 0;
}

.applications-card {
  box-shadow: none;
}

.applications-card .ant-card-head-title {
  font-size: 14px;
  font-weight: 600;
}

.applications-card span.anticon {
  color: $default-grey !important;
}

.applications-card .table-selected-row {
  background-color: #e6f7ff;
}

.upload-container {
  border: 2px solid black;
  border-radius: 15px;
  height: 100%;
  width: 100%;
  padding: 10px;
  cursor: pointer;

  .uploader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    height: 100%;
    width: 100%;
    padding: 20px;
    border: 2px dashed black;
  }
}

.file-drop {
  /* relatively position the container bc the contents are absolute */
  position: relative;
  height: 200px;
  width: 100%;
}

.tags-delete-button {
  display: none !important;
  padding: 0 !important;
  .del-icon {
    color: red;
  }
}

.tags-select-option:hover {
  .tags-delete-button {
    display: inline !important;
  }
}

.tags-select-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Placeholder to reserve space for AntD's checkmark */
.checkmark-placeholder {
  width: 15px; /* Same width as Ant Design's checkmark */
  display: inline-block;
}

.ant-select-item-option-selected .checkmark-placeholder {
  display: none;
}

.update-tag-space {
  .ant-badge-status-dot {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
  .selected-color {
    .ant-badge-status-dot {
      border: 1px solid #ccc;
      box-shadow: 0 0 0 1px #ccc;
    }
  }
}

.booking-del-btn::after {
  display: none;
}

.custom-tags-dropdown .anticon-check {
  vertical-align: bottom !important;
}
.upload-file-container {
  .ant-row {
    display: block;
  }
  .ant-col {
    max-width: 100%;
  }
}

.lead-qualifier-item {
  .lead-qualifier-header {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 8px;
  }

  .lead-qualifier-index {
    min-width: 24px !important;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #00000040;
    border-radius: 50%;
    font-size: 12px !important;
    color: #00000040;
  }

  .lead-qualifier-question {
    margin: 0;
    color: #909090;
    font-size: 12px !important;
  }

  .lead-qualifier-value {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    margin-top: 8px;
  }
}

.lead-qualifier-card {
  .lead-qualifier-answer {
    font-size: 16px;
    font-weight: 600;
    white-space: pre-line;
    margin-bottom: 0px;
  }

  .ant-card-head {
    padding: 0;
    border: none;
  }

  .ant-card-body {
    padding: 0px 16px 16px 8%;
  }
  .ant-card-head-title {
    overflow: visible !important;
    white-space: unset !important;
    padding: 0 !important;
    padding-top: 16px !important;
  }
  .custom-tags-dropdown .anticon-check {
    vertical-align: bottom !important;
  }

  .ant-form-item {
    margin-bottom: 0px;
  }

  i.empty {
    font-weight: 400 !important;
    color: #00000059;
  }
}

.filter-menu {
  .ant-btn__without-background {
    padding: 0px 20px;
    width: 100%;
    text-align: left;
    margin: 5px 0;
    svg {
      width: 22px;
      height: 22px;
    }
  }

  .ant-dropdown-menu-item {
    padding: 0;
  }
}

.manual-booking-form {
  padding: 24px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  margin-top: 24px !important;
}

.manual-booking-modal {
  h3 {
    margin-bottom: 0;
    font-weight: 600;
  }

  .ant-modal-header {
    padding: 24px;
    padding-top: 16px !important;
  }

  .ant-modal-footer {
    border-top: none;
    padding: 24px;
    padding-top: 16px;
  }
  .ant-modal-close-x {
    margin-top: 8px;
  }
  .ant-form-item {
    margin-bottom: 8px !important;
  }
}

.phone-input-overwrite .react-tel-input .country-list {
  position: fixed;
  z-index: 999;
}
