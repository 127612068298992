/* 
  #######################################################################################
  ####################################### WARNING #######################################
  #######################################################################################
  Please don't add changes to this file, it will be automatically overwritten.
  The funnel is the source of truth. Changes need to be made there.
  #######################################################################################
  ####################################### WARNING #######################################
  #######################################################################################
  */
.element-appender__inner {
  display: flex;
  justify-content: center;

  .choice-inner-wrapper {
    background-color: #fff;
    box-shadow: 0px 3px 10px rgb(0 0 0 / 4%);
    border-radius: 8px;
    min-height: 115px;
    overflow: hidden;
    padding: 2rem 1.75rem !important;

    &__active {
      opacity: 0.9;
    }

    @include breakpoint(max_415) {
      &.p-4 {
        padding: 0.5rem 1rem !important;
      }
    }
  }

  .choice-wrapper,
  .add-new-choice-container {
    margin: 0.7rem 0 !important;
    padding: 0rem 0.5rem;
  }

  .choice-wrapper {
    cursor: pointer;

    &:hover {
      transform: scale(1.0175);
    }

    &:hover,
    & {
      transition: ease transform 0.5s;
    }

    .content-wrapper > p {
      width: auto;
      word-break: break-word;
    }

    .icon-provider {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 3.5rem;
    }

    .content-wrapper {
      font-size: 1.085em;
    }
  }

  .add-new-choice-wrapper {
    border: 2px dashed #23d3d3;
    height: 100%;
    .add-icon-wrapper {
      background-color: #23d3d3;
      border-radius: 50%;
      height: 35px;
      width: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        color: #fff;
        height: 18px;
        width: 18px;
      }
    }

    @include defaultOpacityHover;
  }
}

.choice-icon {
  .img-element__wrapper {
    padding: 0;
  }

  .icon-provider {
    height: 4.25rem;
  }
}

.choice-container-hoc {
  max-width: 1000px;
  margin: 0 auto;
}

.justify-space-between {
  justify-content: space-between !important;
}

.choice-icon-on-right {
  .isImageChoice {
    padding-left: 1.5rem;

    .choice-icon {
      margin: -2rem -1.75rem -2rem 0;

      .img-element__wrapper {
        height: 100%;

        .choice-img {
          object-fit: cover;
        }
      }
    }
  }

  .choice-inner-wrapper {
    height: 100%;
    display: flex;
    justify-content: space-between !important;
  }

  .choice-content {
    display: flex;
    align-items: center;
  }

  .choice-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .media-wrapper {
    width: 130px;

    .img-element__wrapper {
      padding: 0px;
      width: inherit;
      .choice-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        &.choice-round {
          height: 150px !important;
          border-radius: 50%;
        }
      }
    }
  }
}

.choice-icon-on-center {
  justify-content: center;

  .isImageChoice {
    padding: 1rem 0 1rem 0;
  }
  justify-content: center;
  .choice-inner-wrapper {
    height: 100%;
    min-height: 200px !important;
    display: flex;
    justify-content: center !important;
    flex-direction: column-reverse;
    align-items: center;
    padding: 10px !important;
    .text-when-image {
      height: 100%;
      justify-content: center !important;
    }
  }

  .choice-content {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    .icon-provider {
      display: none;
    }
  }
  .content-wrapper {
    p {
      text-align: center;
      padding: 5px 0px 0px 0px !important;
      margin: 0px !important;
    }
  }

  .media-wrapper {
    .img-element__wrapper {
      .choice-img {
        width: 100%;
        height: 100%;

        &.choice-round {
          height: 150px !important;
          border-radius: 50%;
        }
      }
    }
  }
}

.choice-icon-on-left {
  position: relative;
  .isImageChoice {
    padding: 1rem 1rem 1rem 1.5rem;
  }

  .choice-inner-wrapper {
    padding: 2rem 1.75rem 2rem 4rem !important;
    display: flex;
    align-items: center;
    margin-left: 1rem;
    .img-element__wrapper {
      width: inherit;
    }
  }

  .choice-content {
    display: flex;
    align-items: center;
  }
}

.choice-icon-on-top {
  position: relative;

  .isImageChoice {
    padding: 1rem 1.5rem;

    .choice-icon {
      .img-element__wrapper {
        padding: 0;
        width: inherit;

        .choice-img {
          object-fit: cover;
        }
      }
    }
  }

  .choice-inner-wrapper {
    height: 100%;
    display: flex;
    justify-content: space-between !important;
  }

  .choice-content {
    display: flex;
    align-items: center;
  }
}

.choice-icon-on {
  &-top,
  &-left {
    .choice-icon {
      width: 60px;
      height: 60px;
      overflow: hidden;
      position: absolute;
      background: #fff;
      box-shadow: 0px 3px 10px rgb(0 0 0 / 20%);
      display: flex;
      align-items: center;
      justify-content: center;

      .icon-provider {
        font-size: 2em;
      }

      .img-element__wrapper {
        height: 100%;

        .choice-img {
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  &-top {
    &.choice-wrapper,
    & .choice-wrapper {
      margin: 1.15rem 0 !important;
    }

    .choice-icon {
      right: 30px;
      top: -20px;
    }
  }

  &-left {
    .choice-icon {
      left: 5px;
    }
  }

  &-center {
    .choice-icon.media-wrapper {
      margin: -0.75rem -0.75rem 0.5rem -0.75rem;
    }
  }
}

/* Overwrites for different behaviour within builder */
.builder-view {
  .choice-wrapper {
    &:hover {
      transform: initial;
    }
  }
}
